import * as i0 from '@angular/core';
import { Injectable, Component, NgModule } from '@angular/core';
import { BehaviorSubject, map, shareReplay, auditTime, filter, merge } from 'rxjs';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import { finalize } from 'rxjs/operators';
const _c0 = a0 => ({
  active: a0
});
class LoadingIndicatorService {
  set requests(requests) {
    this._requests = requests;
    this.requests$.next(requests);
  }
  get requests() {
    return this._requests;
  }
  constructor() {
    this._requests = 0;
    this.requests$ = new BehaviorSubject(0);
  }
  getRequests() {
    return this.requests$;
  }
  start() {
    this.requests++;
  }
  stop() {
    if (this.requests > 0) {
      this.requests--;
    }
  }
  stopAll() {
    this.requests = 0;
  }
  static {
    this.ɵfac = function LoadingIndicatorService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LoadingIndicatorService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LoadingIndicatorService,
      factory: LoadingIndicatorService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoadingIndicatorService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
class LoadingIndicatorComponent {
  constructor(loadingIndicatorService) {
    this.loadingIndicatorService = loadingIndicatorService;
  }
  ngOnInit() {
    const hasRunningRequests$ = this.loadingIndicatorService.getRequests().pipe(map(numberOfRequests => numberOfRequests > 0), shareReplay());
    const startLoading$ = hasRunningRequests$.pipe(
    // http://reactivex.io/rxjs/class/es6/Observable.js~Observable.html#instance-method-auditTime
    // delay the start of the loading indicator for 0,5sec
    auditTime(500));
    const stopLoading$ = hasRunningRequests$.pipe(filter(isLoading => !isLoading));
    this.runningRequests$ = merge(startLoading$, stopLoading$);
  }
  static {
    this.ɵfac = function LoadingIndicatorComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LoadingIndicatorComponent)(i0.ɵɵdirectiveInject(LoadingIndicatorService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LoadingIndicatorComponent,
      selectors: [["aviatar-loading-indicator"]],
      decls: 2,
      vars: 5,
      consts: [[1, "loader", 3, "ngClass"]],
      template: function LoadingIndicatorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", 0);
          i0.ɵɵpipe(1, "async");
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(3, _c0, i0.ɵɵpipeBind1(1, 1, ctx.runningRequests$)));
        }
      },
      dependencies: [i2.NgClass, i2.AsyncPipe],
      styles: [".loader[_ngcontent-%COMP%]{width:100%;position:relative;top:-5px;height:0}.loader.active[_ngcontent-%COMP%]{height:5px;animation-duration:2s;animation-name:_ngcontent-%COMP%_aviatarLoadingAnimation;animation-fill-mode:forwards;animation-iteration-count:infinite;animation-timing-function:linear;background:linear-gradient(to right,#c2c4ef,#3e4080,#676bd6);background-size:200%}@keyframes _ngcontent-%COMP%_aviatarLoadingAnimation{0%{background-position:100% 0}to{background-position:-100% 0}}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoadingIndicatorComponent, [{
    type: Component,
    args: [{
      selector: 'aviatar-loading-indicator',
      template: "<div class=\"loader\" [ngClass]=\"{ active: (runningRequests$ | async) }\"></div>\n",
      styles: [".loader{width:100%;position:relative;top:-5px;height:0}.loader.active{height:5px;animation-duration:2s;animation-name:aviatarLoadingAnimation;animation-fill-mode:forwards;animation-iteration-count:infinite;animation-timing-function:linear;background:linear-gradient(to right,#c2c4ef,#3e4080,#676bd6);background-size:200%}@keyframes aviatarLoadingAnimation{0%{background-position:100% 0}to{background-position:-100% 0}}\n"]
    }]
  }], () => [{
    type: LoadingIndicatorService
  }], null);
})();
class LoadingIndicatorModule {
  static {
    this.ɵfac = function LoadingIndicatorModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LoadingIndicatorModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: LoadingIndicatorModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoadingIndicatorModule, [{
    type: NgModule,
    args: [{
      declarations: [LoadingIndicatorComponent],
      imports: [CommonModule],
      exports: [LoadingIndicatorComponent]
    }]
  }], null, null);
})();
class LoadingIndicatorInterceptor {
  constructor(loadingIndicatorService) {
    this.loadingIndicatorService = loadingIndicatorService;
  }
  intercept(req, next) {
    this.loadingIndicatorService.start();
    return next.handle(req).pipe(finalize(() => {
      this.loadingIndicatorService.stop();
    }));
  }
  static {
    this.ɵfac = function LoadingIndicatorInterceptor_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LoadingIndicatorInterceptor)(i0.ɵɵinject(LoadingIndicatorService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LoadingIndicatorInterceptor,
      factory: LoadingIndicatorInterceptor.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoadingIndicatorInterceptor, [{
    type: Injectable
  }], () => [{
    type: LoadingIndicatorService
  }], null);
})();

/*
 * Public API Surface of loading-indicator
 */

/**
 * Generated bundle index. Do not edit.
 */

export { LoadingIndicatorComponent, LoadingIndicatorInterceptor, LoadingIndicatorModule, LoadingIndicatorService };
